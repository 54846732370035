<template>
  <div class="flex flex-grow justify-center items-center flex-col pl-500px">
    <div class="max-w-400 w-full mx-auto">
      <h2 class="text-4xl font-medium text-black-900 mb-3">Reset password</h2>
      <validation-observer
        tag="form"
        ref="form"
        @submit.prevent="handleSubmit"
        class="space-y-6 mb-11"
      >
        <utils-input
          :validationRules="'required|min:8'"
          :inputName="'Password'"
          :inputType="'password'"
          :isSubmitted="isSubmitted"
          v-model="data.password"
        />

        <utils-input
          :validationRules="'required|min:8'"
          :inputName="'Confirm Password'"
          :inputType="'password'"
          :isSubmitted="isSubmitted"
          v-model="data.password_confirmation"
        />

        <base-button colorScheme="orange" class="mt-8 py-3 w-full">
          <span class="font-normal text-sm"> Reset password</span>
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import BaseButton from "@/components/utils/Button.vue";
import UtilsInput from "@/components/utils/UtilsInput.vue";
import { AuthService } from "@/common/api.service";

export default {
  name: "AuthResetPasswordForm",
  components: {
    "utils-input": UtilsInput,
    "base-button": BaseButton,
    ValidationObserver,
  },
  data() {
    return {
      isSubmitted: false,
      data: {
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      this.isSubmitted = true;

      this.$refs.form.validate().then(async (valid) => {
        if (!valid) {
          return;
        }

        try {
          await AuthService.resetPassword({
            ...this.data,
            token: this.$route.params.token,
            email: this.$route.query.email,
          });

          this.$swal({
            icon: "success",
            title: "Password reset successful",
            text: "Awesome, you've successfully updated your password",
          });
          this.$router.push({ name: "login" });
        } catch (error) {
          const data = error.response.data;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.error,
          });
        }
      });
    },
  },
};
</script>
