<template>
  <div class="bg-white-900 h-full">
    <div class="px-0 mx-auto relative h-full flex">
      <auth-info class="flex-shrink-0" />
      <base-logo class="fixed top-4 left-8" />
      <auth-reset-password-form />
    </div>
  </div>
</template>

<script>
import BaseLogo from "@/components/base/BaseLogo.vue";
import AuthInfo from "@/components/auth/AuthInfo.vue";
import AuthResetPasswordForm from "@/components/auth/AuthResetPasswordForm.vue";

export default {
  components: {
    "base-logo": BaseLogo,
    "auth-info": AuthInfo,
    "auth-reset-password-form": AuthResetPasswordForm,
  },
};
</script>
